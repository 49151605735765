import axios from "axios";
import AuthService from "./AuthService";


const performRequest = (url: string, finishedLoading: any, setFinishedLoading: Function, setData: Function, name: string, navigate: Function) => {
    AuthService.updateToken(() => {
        axios.get(`${process.env.https://crm.buergerenergie-dachauerland.de}${url}`, { headers: { Authorization: `Bearer ${AuthService.getToken()}` } })
            .then(response => {
                if (response.data.status === "OK") {
                    setData(response.data.body)
                } else if (response.data.status === "NO_CONTENT") {
                    navigate('/notmembererror')
                    return;
                } else if (response.data.status === "FORBIDDEN") {
                    navigate('/forbidden')
                    return;
                }
                console.log('Response: ' + name, response.data);

                const tempObject = finishedLoading;
                tempObject[name] = true;
                setFinishedLoading({ ...tempObject, [name]: true })
            }).catch(error => {
                console.error('Error:', error);
            });
    });
}

const getConfig = (setConfig: Function) => {
    axios.get(`${process.env.https://crm.buergerenergie-dachauerland.de}/rest/services/beg_ClientConfigService/getConfig`)
        .then(response => {
            setConfig(response.data.body);
            document.title = "Mitgliederportal " + response.data.body.cooperativeName
        }).catch(error => {
            console.error('Error:', error);
            //if path is already serviceunavailable nothing happens
            //if (window.location.href.split("/")[window.location.href.split("/").length - 2] !== "serviceunavailable"){
            console.log(window.location.pathname)
            if (!window.location.pathname.startsWith("/serviceunavailable")){
                window.location.href = "/serviceunavailable/" + encodeURIComponent(window.location.pathname) ;
            }
        });
}

const downloadAttachment = (fileref: string, name: string) => {
    AuthService.updateToken(() => {
        axios({
            url: `${process.env.https://crm.buergerenergie-dachauerland.de}/rest/files?fileRef=${encodeURIComponent(fileref)}`,
            headers: { Authorization: `Bearer ${AuthService.getToken()}` },
            method: 'GET',
            responseType: 'blob'
        }).then((response) => {
            //https://stackoverflow.com/questions/41938718/how-to-download-files-using-axios
            // create file link in browser's memory
            const href = URL.createObjectURL(response.data);

            // create "a" HTML element with href to file & click
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', `${name}.pdf`);
            document.body.appendChild(link);
            link.click();

            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        })
            
    });
}

const DataService = {
    performRequest,
    getConfig,
    downloadAttachment
};

export default DataService;